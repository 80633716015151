@media (min-width: 1025px) and (max-width: 1260px) {
  .header {
    max-width: 70% !important;
    .content {
      h1 {
        font-size: 65px !important;
      }
      h2 {
        font-size: 48px !important;
      }
      p {
        width: 75% !important;
      }
    }
  }
  .about .leftSide{
    h4{
      line-height: 70px !important;
    }
  }
  
}
