@media (max-width: 1024px) and (min-width: 769px) {
  .container {
    max-width: 85%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  nav {
    .menu {
      a {
        padding-right: 30px !important;
      }
    }
  }
  .header {
    max-width: 80% !important;
    .content {
      h1 {
        font-size: 60px !important;
      }
      h2 {
        font-size: 40px !important;
      }
      p {
        font-size: 18px !important;
        width: 85% !important;
      }
    }
  }
  .about {
    display: block !important;
    .leftSide {
      h4 {
        display: none !important;
      }
      .iconSocail {
        display: none !important;
      }
    }
    .rigthSide {
      margin-top: 25px;
      padding-top: 0;
      margin-bottom: 75px;
    }
  }
  .Projects{
    padding: 0;
  }
  .Project {
    margin-bottom: 10%;
    &:nth-of-type(even) {
      .ProDetails {
        grid-column: 6/-1;
        grid-area: 1/6/-1/-1;
      }
      .ProDetails{
        h4{
          margin-left: auto;
        }
      .tags {
        width: 70%;
        margin-left: auto;
      }}
      
    }
    .ProjDemo {
      grid-column: 6/-1;
      grid-area: 1/6/-1/-1;
      margin-top: -11%;
    }
    .ProDetails {
      grid-column: 1/8;
      grid-area: 1/1/-1/8;
      h4{
        width: 70%;
        padding-bottom: 0;
      }
      .description{
        line-height: 1.3;
        font-size: 16px;

      }
      .tags {
        width: 70%;
        margin-bottom: 15px;
      }
    }
  }
  .conatactTypes{
    grid-template-columns: 1fr 0.2fr;
    
  .directContact{
    
    .title{
      position: absolute;
    }
    .form{
      margin-top: 120px !important;
    }
  }}
  .skills .slider{
    max-width: 100%;
  }
}
